import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor() {
   //this.updateCacheVersion('1.0.11');
  }

  updateCacheVersion(cacheVersion) {
    var currentVersion = window.localStorage.getItem('cacheVersion');
    console.log('currentVersion ' + currentVersion + ' new version ' + cacheVersion);
    if (currentVersion !== cacheVersion) {
        console.log('reloading page...');
        window.localStorage.setItem('cacheVersion', cacheVersion);
        window.location.reload();
    }
  }
}
